import mock from '../mock'

// ** Utils
import { paginateArray } from '../utils'

const data = {
  vehicles: [
    {
      id: 1,
      type: "emoped",
      registration: "FD70 WKW",
      status: "active",
      manufacturer: "Supersoco",
      soc: 0.7,
      hub: "london",
      otrPercentage: 77,
      ownership: {
        type: "lease",
        provider: "ABC Leasing"
      },
      insurance: {
        provider: "Cuvva",
        type: "third-party"
      },
      activity: [
        {
          startTime: 1640788250241,
          endTime: 1640788280241,
          startLocation: {
            coordinate: [51.5271567, -0.0835608],
            name: "48 Hoxton Square"
          },
          endLocation: {
            coordinate: [51.5235123, -0.0785303],
            name: "Shoreditch Highstreet"
          },
          startCharge: 0.8,
          endCharge: 0.69,
          route: "",
          user: 5,
          type: "journey"
        },
        {
          date: 1640788250241,
          type: "service",
          provider: "ABC Servicing",
          work: [
            {
              image: "https://images.giant-bicycles.com/b_white,c_pad,h_650,q_80/nxiztbvtycfguiskc0jg/CADEX-CARBON-RIM-BRAKE-PADS-2PCS-FOR-1-WHEEL-290000057.jpg",
              name: "Brake Pads",
              price: "£89.99"
            },
            {
              image: "https://www.cyclesolutions.co.uk/images/giant/290000033_conduct_hydraulic_disc_brakes_140mm_rotors.jpg",
              name: "Bake Disks",
              price: "£110.00"
            },
            {
              image: "",
              name: "Labour @ £60/hr",
              price: "£120.00"
            }
          ],
          price: "£319.99"
        },
        {
          startTime: 1640788250241,
          endTime: 1640788280241,
          startLocation: [51.5271567, -0.0835608],
          endLocation: [51.5271567, -0.0835608],
          startCharge: 0.69,
          endCharge: 0.92,
          route: "",
          user: 5,
          type: "charge"
        }
      ],
      insights: {
        timeBreakdown: [
          {
            status: "On-The-Road",
            value: 76,
            changeFromPrevious: 4
          },
          {
            status: "In-Service",
            value: 4,
            changeFromPrevious: -3
          },
          {
            status: "Charging",
            value: 16,
            changeFromPrevious: 0
          },
          {
            status: "Other Off Road",
            value: 14,
            changeFromPrevious: 7
          }
        ]
      },
      lastUpdated: "2022-01-01T12:10:55.700Z"
    },
    {
      id: 2,
      type: "ebike",
      registration: "EB001",
      status: "pendingService",
      manufacturer: "Gocycle",
      soc: 0.35,
      hub: "london",
      otrPercentage: 71,
      ownership: {
        type: "purchased"
      },
      insurance: {
        provider: "Admiral",
        type: "fully-comp"
      },
      activity: [
        {
          startTime: 1640788250241,
          endTime: 1640788280241,
          startLocation: {
            coordinate: [51.5271567, -0.0835608],
            name: "48 Hoxton Square"
          },
          endLocation: {
            coordinate: [51.5235123, -0.0785303],
            name: "Shoreditch Highstreet"
          },
          startCharge: 0.8,
          endCharge: 0.69,
          route: "",
          user: 5,
          type: "journey"
        },
        {
          date: 1640788250241,
          type: "service",
          provider: "ABC Servicing",
          work: [
            {
              image: "https://images.giant-bicycles.com/b_white,c_pad,h_650,q_80/nxiztbvtycfguiskc0jg/CADEX-CARBON-RIM-BRAKE-PADS-2PCS-FOR-1-WHEEL-290000057.jpg",
              name: "Brake Pads",
              price: "£89.99"
            },
            {
              image: "https://www.cyclesolutions.co.uk/images/giant/290000033_conduct_hydraulic_disc_brakes_140mm_rotors.jpg",
              name: "Bake Disks",
              price: "£110.00"
            },
            {
              image: "",
              name: "Labour @ £60/hr",
              price: "£120.00"
            }
          ],
          price: "£319.99"
        },
        {
          startTime: 1640788250241,
          endTime: 1640788280241,
          startLocation: [51.5271567, -0.0835608],
          endLocation: [51.5271567, -0.0835608],
          startCharge: 0.69,
          endCharge: 0.92,
          route: "",
          user: 5,
          type: "charge"
        }
      ],
      insights: {
        timeBreakdown: [
          {
            status: "On-The-Road",
            value: 76,
            changeFromPrevious: 4
          },
          {
            status: "In-Service",
            value: 4,
            changeFromPrevious: -3
          },
          {
            status: "Charging",
            value: 16,
            changeFromPrevious: 0
          },
          {
            status: "Other Off Road",
            value: 14,
            changeFromPrevious: 7
          }
        ]
      },
      lastUpdated: "2022-01-01T12:10:55.700Z"
    },
    {
      id: 3,
      type: "ebike",
      registration: "EB002",
      status: "active",
      manufacturer: "Raleigh",
      soc: 0.55,
      hub: "manchester",
      otrPercentage: 67,
      ownership: {
        type: "lease",
        provider: "ABC Leasing"
      },
      insurance: {
        provider: "BikeInsure",
        type: "third-party"
      },
      activity: [
        {
          startTime: 1640788250241,
          endTime: 1640788280241,
          startLocation: {
            coordinate: [51.5271567, -0.0835608],
            name: "48 Hoxton Square"
          },
          endLocation: {
            coordinate: [51.5235123, -0.0785303],
            name: "Shoreditch Highstreet"
          },
          startCharge: 0.8,
          endCharge: 0.69,
          route: "",
          user: 5,
          type: "journey"
        },
        {
          date: 1640788250241,
          type: "service",
          provider: "ABC Servicing",
          work: [
            {
              image: "https://images.giant-bicycles.com/b_white,c_pad,h_650,q_80/nxiztbvtycfguiskc0jg/CADEX-CARBON-RIM-BRAKE-PADS-2PCS-FOR-1-WHEEL-290000057.jpg",
              name: "Brake Pads",
              price: "£89.99"
            },
            {
              image: "https://www.cyclesolutions.co.uk/images/giant/290000033_conduct_hydraulic_disc_brakes_140mm_rotors.jpg",
              name: "Bake Disks",
              price: "£110.00"
            },
            {
              image: "",
              name: "Labour @ £60/hr",
              price: "£120.00"
            }
          ],
          price: "£319.99"
        },
        {
          startTime: 1640788250241,
          endTime: 1640788280241,
          startLocation: [51.5271567, -0.0835608],
          endLocation: [51.5271567, -0.0835608],
          startCharge: 0.69,
          endCharge: 0.92,
          route: "",
          user: 5,
          type: "charge"
        }
      ],
      insights: {
        timeBreakdown: [
          {
            status: "On-The-Road",
            value: 76,
            changeFromPrevious: 4
          },
          {
            status: "In-Service",
            value: 4,
            changeFromPrevious: -3
          },
          {
            status: "Charging",
            value: 16,
            changeFromPrevious: 0
          },
          {
            status: "Other Off Road",
            value: 14,
            changeFromPrevious: 7
          }
        ]
      },
      lastUpdated: "2022-01-01T12:10:55.700Z"
    },
    {
      id: 4,
      type: "cargobike",
      registration: "CB001",
      status: "inoperable",
      manufacturer: "Raleigh",
      soc: 0,
      hub: "leeds",
      otrPercentage: 47,
      ownership: {
        type: "purchased"
      },
      insurance: {
        provider: "BikeInsure",
        type: "third-party"
      },
      activity: [
        {
          startTime: 1640788250241,
          endTime: 1640788280241,
          startLocation: {
            coordinate: [51.5271567, -0.0835608],
            name: "48 Hoxton Square"
          },
          endLocation: {
            coordinate: [51.5235123, -0.0785303],
            name: "Shoreditch Highstreet"
          },
          startCharge: 0.8,
          endCharge: 0.69,
          route: "",
          user: 5,
          type: "journey"
        },
        {
          date: 1640788250241,
          type: "service",
          provider: "ABC Servicing",
          work: [
            {
              image: "https://images.giant-bicycles.com/b_white,c_pad,h_650,q_80/nxiztbvtycfguiskc0jg/CADEX-CARBON-RIM-BRAKE-PADS-2PCS-FOR-1-WHEEL-290000057.jpg",
              name: "Brake Pads",
              price: "£89.99"
            },
            {
              image: "https://www.cyclesolutions.co.uk/images/giant/290000033_conduct_hydraulic_disc_brakes_140mm_rotors.jpg",
              name: "Bake Disks",
              price: "£110.00"
            },
            {
              image: "",
              name: "Labour @ £60/hr",
              price: "£120.00"
            }
          ],
          price: "£319.99"
        },
        {
          startTime: 1640788250241,
          endTime: 1640788280241,
          startLocation: [51.5271567, -0.0835608],
          endLocation: [51.5271567, -0.0835608],
          startCharge: 0.69,
          endCharge: 0.92,
          route: "",
          user: 5,
          type: "charge"
        }
      ],
      insights: {
        timeBreakdown: [
          {
            status: "On-The-Road",
            value: 76,
            changeFromPrevious: 4
          },
          {
            status: "In-Service",
            value: 4,
            changeFromPrevious: -3
          },
          {
            status: "Charging",
            value: 16,
            changeFromPrevious: 0
          },
          {
            status: "Other Off Road",
            value: 14,
            changeFromPrevious: 7
          }
        ]
      },
      lastUpdated: "2022-01-01T12:10:55.700Z"
    }
  ]
}

// GET ALL DATA
mock.onGet('/api/vehicles/list/all-data').reply(() => {
  return [200, data.vehicles]
})

// POST: Add new user
mock.onPost('/api/vehicles/add-vehicle').reply(config => {
  // Get event from post data
  const vehicle = JSON.parse(config.data)
  const highestValue = data.vehicles.reduce((a, b) => (a.id > b.id ? a : b)).id

  vehicle.id = highestValue + 1

  data.vehicles.push(vehicle)

  return [201, { vehicle }]
})

// UPDATE Vehicle
mock.onPost('/api/vehicles/update-vehicle').reply(config => {
  const incomingData = JSON.parse(config.data)
  console.log("Data", incomingData)
  const { id, fields } = incomingData
  const vehicle = data.vehicles.find(i => i.id === id)
  console.log("Vehicle is", vehicle)
  Object.entries(fields).forEach(([key, value]) => {
    console.log("Key", key, "Value", value)
    vehicle[key] = value
  })
  const index = data.vehicles.indexOf(i => i.id === id)
  data.vehicles[index] = vehicle

  return [200, { vehicle }]
})

// UPDATE Vehicle
mock.onPost('/api/vehicles/add-note').reply(config => {
  const incomingData = JSON.parse(config.data)
  const { id, note } = incomingData
  
  const vehicle = data.vehicles.find(i => i.id === id)
  if ('activity' in vehicle) {
    vehicle.activity.push(note)
  } else {
    vehicle.activity = [note]
  }
  
  const index = data.vehicles.indexOf(i => i.id === id)
  data.vehicles[index] = vehicle

  return [200, { vehicle }]
})

// GET Updated DATA
mock.onGet('/api/vehicles/list/data').reply(config => {
  const {
    q = '',
    page = 1,
    hub = null,
    perPage = 10,
    sort = 'asc',
    status = null,
    type = null,
    sortColumn = 'id'
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()

  const dataAsc = data.vehicles.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -1 : 1))

  const dataToFilter = sort === 'asc' ? dataAsc : dataAsc.reverse()

  const filteredData = dataToFilter.filter(vehicle => {
    const vehicleId = String(vehicle.id)
    const exp = (vehicleId.toLowerCase().includes(queryLowered) ||
    vehicle.type.toLowerCase().includes(queryLowered) ||
    vehicle.registration.toLowerCase().includes(queryLowered)) &&
    vehicle.hub === (hub || vehicle.hub) &&
    vehicle.status === (status || vehicle.status) &&
    vehicle.type === (type || vehicle.type) &&
    vehicle.status === (status || vehicle.status)
    return exp
  })
  /* eslint-enable  */

  return [
    200,
    {
      total: filteredData.length,
      vehicles: paginateArray(filteredData, perPage, page)
    }
  ]
})

// GET USER
mock.onGet('/api/vehicles/vehicle').reply(config => {
  const { id } = config
  const vehicle = data.vehicles.find(i => i.id === id)
  return [200, { vehicle }]
})

// DELETE: Deletes User
mock.onDelete('/api/vehicles/delete').reply(config => {
  // Get user id from URL
  let vehicleId = config.id

  // Convert Id to number
  vehicleId = Number(vehicleId)

  const vehicleIndex = data.vehicles.findIndex(t => t.id === vehicleId)
  data.vehicles.splice(vehicleIndex, 1)

  return [200]
})
