/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { useHistory } from 'react-router-dom'
import { handleError, clearError } from '../../../utility/handleError'

// ** Axios Imports
import axios from 'axios'
import { API_ENDPOINT } from '@api/index'

export const getData = createAsyncThunk('hubs/getData', async params => {
  // Filter out any null or empty string parameters
  const filteredParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null) {
      filteredParams[key] = params[key]
    }
  }
  console.log(`Params`, filteredParams)
  // Build the url before making request
  const urlParams = new URLSearchParams(filteredParams).toString()
  const url = `${API_ENDPOINT}/hubs?${urlParams}`
  console.log("URL", url)

  const response = await axios.get(url)
  .catch(err => {
    handleError(err)
    console.log("ERROR", err)
  })

  console.log("response.data", response.data)

  return response.data
})

export const getHub = createAsyncThunk('hubs/getHub', async id => {
  const response = await axios.get(`${API_ENDPOINT}/hubs/${id}`)
  console.log("getHubResp", response.data)
  return response.data
})

export const updateHub = createAsyncThunk('hubs/updateHub', async payload => {
  const response = await axios.put(`${API_ENDPOINT}/hubs/${payload.id}`, payload)
  return response.data
})

export const addHub = createAsyncThunk('hubs/addHub', async (hub, { dispatch }) => {
  const payload = {
    hubs: [hub]
  }
  const response = await axios.post(`${API_ENDPOINT}/hubs`, payload)
  await dispatch(getData())
  console.log(`Add Hub`, response)
  return user
})

export const deleteHub = createAsyncThunk('hubs/deleteHub', async (params, { dispatch }) => {
  await axios.delete(`${API_ENDPOINT}/hubs/${params.id}`).then(res => {
    console.log(res)
  })
  dispatch(getData({
    page: params.page
  }))
  return id
})

export const hubsSlice = createSlice({
  name: 'hubs',
  initialState: {
    hubData: null,
    selectedHub: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.hubData = action.payload
      })
      .addCase(getHub.fulfilled, (state, action) => {
        state.selectedHub = action.payload
      })
  }
})

export const presignCSV = createAsyncThunk('hubs/presignCSV', async payload => {
  const response = await axios.post(`${API_ENDPOINT}/presign_csv_post`, payload)
  return response.data
})

export const uploadFile = createAsyncThunk('hubs/uploadFile', async (payload) => {
  const uninterceptedAxiosInstance = axios.create()
  const response = await uninterceptedAxiosInstance.post(payload.url, payload.formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }, {}).catch(err => {
    console.log("Upload file RES ERR", err.response.data)
  })
  console.log("Upload file Response", response)
  return response.data
})

export const importHubs = createAsyncThunk('hubs/importHubs', async payload => {
  const response = await axios.post(`${API_ENDPOINT}/import/hubs`, payload)
  .catch(err => {
    return err.response
  })
  console.log("import response before return", response)
  return response
})

export default hubsSlice.reducer
