/* eslint-disable no-unused-vars */
import { handleErrorModal } from '@store/layout'
import { store } from '../redux/store'

export function handleError(error, statusCode = 0) {
    let errorMessage = ""

    if (error === null || error === undefined) {
        errorMessage = "Something went wrong!"
    }

    if (error && 'response' in error && statusCode > 501) {
        store.dispatch(handleErrorModal({
            title: "Uh Oh!",
            body: "Papaya server is down and we're working hard to fix it - please try again later"
        }))
    }

    if (error && (error?.message || error?.message_list)) {
        errorMessage = error.message || error.message_list
    } else if (error !== null) {
        errorMessage = error
    }

    store.dispatch(handleErrorModal({
        title: "Uh Oh!",
        body: errorMessage
    }))
}

export function handleModal(title, body) {
    store.dispatch(handleErrorModal({
        title,
        body
    }))
}

export function clearError() {
    store.dispatch(handleErrorModal({}))
}

