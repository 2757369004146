/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { useHistory } from 'react-router-dom'
import { handleError, clearError } from '../../../utility/handleError'

// ** Axios Imports
import axios from 'axios'
import { API_ENDPOINT } from '@api/index'

export const getData = createAsyncThunk('appUsers/getData', async params => {
  // Filter out any null or empty string parameters
  const filteredParams = {}
  for (const key in params) {
    if (params[key] !== '' && params[key] !== undefined && params[key] !== null) {
      filteredParams[key] = params[key]
    }
  }
  console.log(`Params`, filteredParams)
  // Build the url before making request
  const urlParams = new URLSearchParams(filteredParams).toString()
  const url = `${API_ENDPOINT}/users?${urlParams}`
  console.log("URL", url)

  const response = await axios.get(url)
  .catch(err => {
    handleError(err)
    console.log("ERROR", err)
  })

  console.log("response.data", response.data)

  return response.data
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get(`${API_ENDPOINT}/users/${id}`)
  console.log("getUserResp", response.data)
  return response.data
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async payload => {
  const response = await axios.put(`${API_ENDPOINT}/users/${payload.id}`, payload)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch }) => {
  const payload = {
    users: [user]
  }
  const response = await axios.post(`${API_ENDPOINT}/users`, payload)
  await dispatch(getData())
  console.log(`Add User`, response)
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (params, { dispatch }) => {
  await axios.delete(`${API_ENDPOINT}/users/${params.id}`).then(res => {
    console.log(res)
  })
  dispatch(getData({
    page: params.page
  }))
  return id
})

export const presignCSV = createAsyncThunk('appUsers/presignCSV', async payload => {
  const response = await axios.post(`${API_ENDPOINT}/presign_csv_post`, payload)
  return response.data
})

export const uploadFile = createAsyncThunk('appUsers/uploadFile', async (payload) => {
  const uninterceptedAxiosInstance = axios.create()
  const response = await uninterceptedAxiosInstance.post(payload.url, payload.formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }, {}).catch(err => {
    console.log("RES ERR", err.response.data)
  })
  console.log("Response", response)
  return response.data
})

export const importUsers = createAsyncThunk('appUsers/importUsers', async payload => {
  const response = await axios.post(`${API_ENDPOINT}/import/users`, payload)
  .catch(err => {
    return err.response
  })
  console.log("import response before return", response)
  return response
})


export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    userData: {},
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.userData = action.payload
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appUsersSlice.reducer
