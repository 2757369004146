// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import config from './config'
import auth from './authentication'
import vehicles from '../views/vehicles/store'
import fleetUsers from '../views/fleet_users/store'
import users from '../views/users/store'
import hubs from '../views/hubs/store'
import tickets from '../views/tickets/store'
import todo from '../views/task_list/store'
import completeAccount from '../views/complete_creation/store'

const rootReducer = {
  auth,
  navbar,
  layout,
  config,
  vehicles,
  fleetUsers,
  users,
  hubs,
  tickets,
  todo,
  completeAccount
}

export default rootReducer
