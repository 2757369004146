import { QueryClient } from 'react-query'
import axios from 'axios'

export const API_ENDPOINT = process.env.NODE_ENV === 'production' ? 'https://api.papayadash.com' : `https://papaya-dashboard-api.herokuapp.com`

const defaultQueryFn = async ({ queryKey }) => {
  const res = await axios.get(`${API_ENDPOINT}${queryKey[0]}`)
  if (res.status < 300) return res.data
}

export const queryClient = new QueryClient({
   defaultOptions: {
     queries: {
       staleTime: 180000,
       cacheTime: Infinity,
       queryFn: defaultQueryFn
     }
   }
})

export const users = {
  update: (user) => {
    return axios.put(`${API_ENDPOINT}/users/${user.id}`, {
      ...user
    })
  }
}

export const vehicles = {
  all: async ({queryKey}) => {
    const [KEY, {...params}] = queryKey
    const res = await axios.get(`${API_ENDPOINT}/vehicles`, {
      params
    })
    if (res.status < 300) return res.data
  },
  update: async (vehicle) => {
    const res = await axios.put(`${API_ENDPOINT}/vehicles/${vehicle.id}`, {
      ...vehicle
    })
    if (res.status < 300) return res.data
  }
}

export const tickets = {
  create: async(ticket) => {
    const res = await axios.post(`${API_ENDPOINT}/tickets`, ticket)
    if (res.status < 300) return res.data
  },
  comment: async({ticket, ...comment}) => {
    const res = await axios.post(`${API_ENDPOINT}/tickets/${ticket}/ticket_comments`, comment)
    if (res.status < 300) return res.data
  },
  update: async(ticket) => {
    const res = await axios.put(`${API_ENDPOINT}/tickets/${ticket.id}`, ticket)
    if (res.status < 300) return res.data
  }
}

export const activities = {
  all: async ({ queryKey }) => {
    const [KEY, { page, resourceType, resourceId, per_page }] = queryKey
    const res = await axios.get(`${API_ENDPOINT}/activities`, {
      params: {
        per_page: 25 || per_page,
        page: page || 1,
        resource_type: resourceType || null,
        resource_id: resourceId || null
      }
    })
    if (res.status < 300) return res.data
  },
  create: async (activity) => {
    const res = await axios.post(`${API_ENDPOINT}/activities`, {
      ...activity
    })
    if (res.status < 300) return res.data
  }
}
