// ** JWT Service Import
import JwtService from "./jwtService"

// ** Export Service as getJwt
export default function getJwt(jwtOverrideConfig) {
  const jwt = new JwtService(jwtOverrideConfig)

  return {
    jwt
  }
}
