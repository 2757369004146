// // ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { API_ENDPOINT } from '@api/index'

export const getAllData = createAsyncThunk('appVehicles/getAllData', async () => {
  const response = await axios.get('/api/vehicles/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('appVehicles/getData', async params => {
  const response = await axios.get('/api/vehicles/list/data', params)
  return {
    params,
    data: response.data.vehicles,
    totalPages: response.data.total
  }
})

export const getVehicle = createAsyncThunk('appVehicles/getVehicle', async id => {
  const response = await axios.get('/api/vehicles/vehicle', { id })
  return response.data.vehicle
})

export const addVehicle = createAsyncThunk('appVehicles/addVehicle', async (vehicle, { dispatch, getState }) => {
  await axios.post('/api/vehicles/add-vehicle', vehicle)
  await dispatch(getData(getState().vehicles.params))
  await dispatch(getAllData())
  return user
})

export const updateVehicle = createAsyncThunk('appVehicles/updateVehicles', async (data) => {
  await axios.post('/api/vehicles/update-vehicle', data)
  return data
})

export const addNote = createAsyncThunk('appVehicles/addNoteToVehicle', async (data) => {
  await axios.post('/api/vehicles/add-note', data)
  return data
})

export const deleteVehicle = createAsyncThunk('appVehicles/deleteVehicle', async (id, { dispatch, getState }) => {
  await axios.delete('/api/vehicles/delete', { id })
  await dispatch(getData(getState().vehicles.params))
  await dispatch(getAllData())
  return id
})

export const presignCSV = createAsyncThunk('appUsers/presignCSV', async payload => {
  const response = await axios.post(`${API_ENDPOINT}/presign_csv_post`, payload)
  return response.data
})

export const uploadFile = createAsyncThunk('appVehicles/uploadFile', async (payload) => {
  const uninterceptedAxiosInstance = axios.create()
  const response = await uninterceptedAxiosInstance.post(payload.url, payload.formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }, {})
  return response.data
})

export const importVehicles = createAsyncThunk('appVehicles/importVehicles', async payload => {
  const response = await axios.post(`${API_ENDPOINT}/import/vehicles`, payload)
  .catch(err => {
    return err.response
  })
  console.log("import response before return", response)
  return response
})

export const appVehiclesSlice = createSlice({
  name: 'appVehicles',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedVehicle: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getVehicle.fulfilled, (state, action) => {
        state.selectedVehicle = action.payload
      })
  }
})

export default appVehiclesSlice.reducer
