// // ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { API_ENDPOINT } from '@api/index'

export const companyShowIncomplete = createAsyncThunk('completeCreation/companyShowIncomplete', async (input) => {
    const response = await axios.get(`${API_ENDPOINT}/companies/${input}/show_incomplete`)
    .catch(err => {
        return {
            data: err.response.data
        }
    })
    return response.data
})

export const userShowIncomplete = createAsyncThunk('completeCreation/userShowIncomplete', async (input) => {
    const response = await axios.get(`${API_ENDPOINT}/users/${input}/show_incomplete`)
    .catch(err => {
        return {
            data: err.response.data
        }
    })
    return response.data
})

export const getConfig = createAsyncThunk('completeCreation/getConfig', async () => {
    const response = await axios.get(`${API_ENDPOINT}/config`)
    return response.data
})

export const completeCompanyCreation = createAsyncThunk('completeCreation/completeCompanyCreation', async (payload) => {
    const response = await axios.put(`${API_ENDPOINT}/companies/${payload.company_id}/complete_creation`, payload)
    return response.status
})

export const completeUserCreation = createAsyncThunk('completeCreation/completeUserCreation', async (payload) => {
    const response = await axios.put(`${API_ENDPOINT}/users/${payload.id}/complete_creation`, payload)
    return response.status
})

export const completeAccount = createSlice({
    name: 'completeAccount',
    initialState: {
      userIncomplete: null,
      companyIncomplete: null,
      config: null
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getConfig.fulfilled, (state, action) => {
            state.config = action.payload
        })
        .addCase(companyShowIncomplete.fulfilled, (state, action) => {
            state.companyIncomplete = action.payload
        })
        .addCase(userShowIncomplete.fulfilled, (state, action) => {
            state.userIncomplete = action.payload
        })
    }
  })

  export default completeAccount.reducer
