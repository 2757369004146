// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { API_ENDPOINT } from '@api/index'

// ** Axios Imports
import axios from 'axios'

export const getConfig = createAsyncThunk('appUsers/getConfig', async () => {
  const response = await axios.get(`${API_ENDPOINT}/config`)
  return response.data
})

export const appUsersSlice = createSlice({
  name: 'config',
  initialState: {
    config: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
        .addCase(getConfig.fulfilled, (state, action) => {
            state.config = action.payload
        })
  }
})

export default appUsersSlice.reducer
